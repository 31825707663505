import { useEffect, useState } from "react";
import { LOCAL_STORAGE_KEYS } from "../local_storage_keys";
import { useLocalStorage } from "./use_local_storage";
import { useDecodeJwt } from "./use_decode_jwt";

export function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [isStaff, setIsStaff] = useState(false);
  const [userFullName, setUserFullName] = useState("");
  const [userInitials, setUserInitials] = useState("");
  const { decodedJwt, setJwtToken } = useDecodeJwt();
  const { getItem } = useLocalStorage(LOCAL_STORAGE_KEYS.tokens);

  useEffect(() => {
    const userTokens = getItem();
    if (userTokens) {
      setJwtToken(userTokens?.access);
      setIsSignedIn((r) => true);
      // console.log(decodedJwt);
      setIsStaff((_) => decodedJwt?.is_staff);
      setUserFullName(
        (_) => `${decodedJwt?.user_first_name} ${decodedJwt?.user_surname}`
      );
      setUserInitials(
        (_) =>
          `${String(decodedJwt?.user_first_name).charAt(0)}${String(
            decodedJwt?.user_surname
          ).charAt(0)}`
      );
      // setUserFullName()
    } else {
      setIsSignedIn((r) => false);
    }
  }, [getItem()]);

  return { isSignedIn, userFullName, userInitials, isStaff };
}
