import React, { useState } from "react";
import { ROUTE_LINKS } from "../../../utils/route_links";
import { Button } from "../custom_button";
import { useMutation } from "react-query";
import { signoutFunction } from "../../../api/api";
import { useLocalStorage } from "../../../utils/hooks/use_local_storage";
import { LOCAL_STORAGE_KEYS } from "../../../utils/local_storage_keys";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

export function SignedInMobileMenu() {
  const { removeItem } = useLocalStorage(LOCAL_STORAGE_KEYS.tokens);
  const [disableUserInterface, setDisableUserInterface] = useState();
  //
  const {
    isLoading: isRequestLoading,
    // isSuccess,
    mutateAsync: signOutAsync,
    // isError,
    // error,
  } = useMutation({
    mutationFn: signoutFunction,
    onError: (e) => {
      // console.log(e);
      setDisableUserInterface((r) => false);
    },
    onSuccess: (data) => {
      // console.log(data);
      removeItem();
      setDisableUserInterface((r) => true);
      window.location.href = ROUTE_LINKS.home;
      // refresh the page or redirect
    },
    onMutate: (variables) => {
      setDisableUserInterface((r) => true);
    },
  });

  const handleSignout = async () => {
    try {
      await signOutAsync();
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <nav
      className="flex flex-col min-h-screen items-start justify-start py-8 px-6 gap-8 bg-slate-800"
      aria-label="mobile"
    >
      <Link className="text-3xl" reloadDocument to={ROUTE_LINKS.profile}>
        PROFILE
      </Link>
      <Link className="text-3xl" reloadDocument to={ROUTE_LINKS.bookings}>
        BOOKINGS
      </Link>
      <Link className="text-3xl" reloadDocument to={ROUTE_LINKS.faqs}>
        FAQ'S
      </Link>
      <Button
        disabled={disableUserInterface}
        onClick={handleSignout}
        className="w-auto text-lg px-4 font-bold"
      >
        {isRequestLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <ThreeDots visible width="40" height="20" color="#000" />
          </div>
        ) : (
          "SIGN OUT"
        )}{" "}
      </Button>
    </nav>
  );
}

export function SignedOutMobileMenu() {
  return (
    <nav
      className="flex flex-col min-h-screen items-start justify-start py-8 px-6 gap-8 bg-slate-800"
      aria-label="mobile"
    >
      <a className="text-3xl" href={ROUTE_LINKS.sigin}>
        SIGN IN
      </a>
      <a className="text-3xl" href={ROUTE_LINKS.register}>
        REGISTER
      </a>
      <a className="text-3xl" href={ROUTE_LINKS.faqs}>
        FAQ'S
      </a>
    </nav>
  );
}

export function SignedInDesktopMenu() {
  const { removeItem } = useLocalStorage(LOCAL_STORAGE_KEYS.tokens);
  const [disableUserInterface, setDisableUserInterface] = useState();
  //
  const {
    isLoading: isRequestLoading,
    // isSuccess,
    mutateAsync: signOutAsync,
    // isError,
    // error,
  } = useMutation("signout", {
    mutationFn: signoutFunction,
    onError: (e) => {
      // console.log(e);
      setDisableUserInterface((r) => false);
    },
    onSuccess: (data) => {
      // console.log(data);
      removeItem();
      setDisableUserInterface((r) => true);
      window.location.href = ROUTE_LINKS.home;
      // refresh the page or redirect
    },
    onMutate: (variables) => {
      setDisableUserInterface((r) => true);
    },
  });

  const handleSignout = async () => {
    try {
      await signOutAsync();
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <nav className="hidden sm:block space-x-8 text-xl" aria-label="navbar-menu">
      <a className="hover:opacity-80" href={ROUTE_LINKS.profile}>
        Profile
      </a>
      <a className="hover:opacity-80" href={ROUTE_LINKS.bookings}>
        Bookings
      </a>
      <a className="hover:opacity-80" href={ROUTE_LINKS.faqs}>
        FAQ's
      </a>
      <Button
        disabled={disableUserInterface}
        onClick={handleSignout}
        className="w-auto text-base font-bold"
      >
        {isRequestLoading ? (
          <div className="w-full h-full flex justify-center items-center">
            <ThreeDots visible width="40" height="20" color="#000" />
          </div>
        ) : (
          "Sign out"
        )}{" "}
      </Button>
    </nav>
  );
}
export function SignedOutDesktopMenu() {
  return (
    <nav className="hidden sm:block space-x-8 text-xl" aria-label="navbar-menu">
      <a className="hover:opacity-80" href={ROUTE_LINKS.sigin}>
        Sign in
      </a>

      <a className="hover:opacity-80" href={ROUTE_LINKS.register}>
        Register
      </a>
      <a className="hover:opacity-80" href={ROUTE_LINKS.faqs}>
        FAQ's
      </a>
    </nav>
  );
}
