import React from "react";
import { Routes, Route } from "react-router-dom";
import { ROUTE_LINKS } from "./route_links";
import { LazyLoading } from "./lazyloading";

//pages

const Home = React.lazy(() => import("../components/pages/home"));
const ViewBooking = React.lazy(() =>
  import("../components/pages/view_booking")
);

const ResetPassword = React.lazy(() =>
  import("../components/pages/reset_password")
);
const Signin = React.lazy(() => import("../components/pages/sign_in"));
const Profile = React.lazy(() => import("../components/pages/profile"));
const Register = React.lazy(() => import("../components/pages/register"));
const Bookings = React.lazy(() => import("../components/pages/bookings"));
const FeedBack = React.lazy(() => import("../components/pages/feed_back"));
const RequestPasswordReset = React.lazy(() =>
  import("../components/pages/request_password_reset")
);

const CreateBooking = React.lazy(() =>
  import("../components/pages/book_booking")
);
const FrequentlyAskedQuestions = React.lazy(() =>
  import("../components/pages/frequently_asked_questions")
);
const ChooseVehicleType = React.lazy(() =>
  import("../components/pages/choose_vehicle_type")
);
const ChooseRoutes = React.lazy(() =>
  import("../components/pages/choose_routes")
);
const ChooseAdditionalInformation = React.lazy(() =>
  import("../components/pages/choose_additional_information")
);

//
export function RegisteredRoutes() {
  return (
    <Routes>
      <Route
        path={ROUTE_LINKS.home}
        element={
          <LazyLoading>
            <Home />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.sigin}
        element={
          <LazyLoading>
            <Signin />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.profile}
        element={
          <LazyLoading>
            <Profile />
          </LazyLoading>
        }
      />

      <Route
        path={ROUTE_LINKS.register}
        element={
          <LazyLoading>
            <Register />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.bookings}
        element={
          <LazyLoading>
            <Bookings />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.createbooking}
        element={
          <LazyLoading>
            <ChooseVehicleType />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.faqs}
        element={
          <LazyLoading>
            <FrequentlyAskedQuestions />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.chooseroutes}
        element={
          <LazyLoading>
            <ChooseRoutes />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.chooseadditionalinformation}
        element={
          <LazyLoading>
            <ChooseAdditionalInformation />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.makebooking}
        element={
          <LazyLoading>
            <CreateBooking />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.viewbooking}
        element={
          <LazyLoading>
            <ViewBooking />
          </LazyLoading>
        }
      />

      <Route
        path={ROUTE_LINKS.feedback}
        element={
          <LazyLoading>
            <FeedBack />
          </LazyLoading>
        }
      />

      <Route
        path={ROUTE_LINKS.requestpasswordreset}
        element={
          <LazyLoading>
            <RequestPasswordReset />
          </LazyLoading>
        }
      />
      <Route
        path={ROUTE_LINKS.resetpassword}
        element={
          <LazyLoading>
            <ResetPassword />
          </LazyLoading>
        }
      />
    </Routes>
  );
}
