import { RiTiktokLine, RiFacebookBoxLine } from "react-icons/ri";
import Terms from "../../pdf/matols.pdf";

export function Footer() {
  return (
    <footer id="footer" className="bg-slate-800 text-yellow-400 text-md">
      <section className="max-w-4xl mx-auto p-6 flex gap-6 flex-col sm:flex-row sm:justify-between">
        <address className="text-sm">
          <h2 className="text-sm">Matols Logistic Services (Pty) Ltd.</h2>
          <div className="h-2" />
          <span className="font-extrabold">Gauteng office address:</span> 3227
          Skhulu St, Molder East 72-jr,
          <br />
          Daveyton Gauteng, Johannesburg South Africa - 1520 <br />
          <div className="h-2" />
          <span className="font-extrabold">
            Eastern Cape office address:
          </span>{" "}
          54 Butterworth Road, Nqamakwe 4990
          <br />
          <div className="h-2" />
          <span className="font-extrabold">Email:</span>{" "}
          <a
            href="mailto:matolslogisticsservices@gmail.com"
            className="hover:text-yellow-500"
          >
            matolslogisticsservices@gmail.com
          </a>
          <br />
          <span className="font-extrabold"> On Whatsapp:</span>{" "}
          <a href="https://wa.link/kr8bk1" className="hover:text-yellow-500">
            083 877 6323
          </a>
          <br />
          <span className="font-extrabold">On Calls:</span>{" "}
          <a href="tel:0785267751" className="hover:text-yellow-500">
            078 526 7751
          </a>
        </address>

        <div className="flex flex-col sm:gap-2 text-sm">
          <h2 className="font-bold italic mb-2">Find us on</h2>
          <div className="flex flex-row gap-2">
            <a href="https://tiktok.com/@3227_2616">
              <RiTiktokLine className="hover:text-yellow-500" size={25} />
            </a>
            <a href="https://www.facebook.com/profile.php?id=100083274296581/">
              <RiFacebookBoxLine className="hover:text-yellow-500" size={25} />
            </a>
          </div>
        </div>
        <div className="flex flex-col sm:gap-2 text-sm">
          <p className="text-right">
            Copyright &copy; <span id="year">2024</span>
          </p>
          <p className="text-right">All rights reserverd</p>
        </div>
      </section>
      <section className="max-w-4xl mx-auto p-6">
        <div className="flex flex-row sm:gap-2 text-sm">
          <a
            href={`${Terms}`}
            target="__blank"
            className="text-center font-bold hover:text-yellow-500"
          >
            Terms & conditions
          </a>
        </div>
      </section>
    </footer>
  );
}
