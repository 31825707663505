import { useCallback, useEffect, useRef } from "react";

export function useTimeout(callback, delay) {
  const callBackRef = useRef(callback);
  const timeOutRef = useRef();

  useEffect(() => {
    callBackRef.current = callback;
  }, [callback]);

  const set = useCallback(() => {
    timeOutRef.current = setTimeout(() => callBackRef.current(), delay);
  }, [delay]);

  const resetCallBack = (newCallBack) => {
    timeOutRef.current = setTimeout(() => newCallBack(), delay);
  };

  return { set, resetCallBack };
}
