import React from "react";
import { LazyLoader } from "../components/reusable_components/lazy_loading_component";

export function LazyLoading({ children }) {
  return (
    <React.Suspense fallback={<LazyLoader text="loading ..." />}>
      {children}
    </React.Suspense>
  );
}
