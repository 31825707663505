import React from "react";
import { cn } from "../../utils/custom_functions";

export function Button({ className, children, ...props }) {
  return (
    <button
      {...props}
      className={cn(
        "w-full bg-yellow-400 px-4 text-base py-2 rounded-md text-slate-800 disabled:bg-yellow-200 disabled:text-opacity-75 disabled:pointer-events-none hover:bg-yellow-500",
        className
      )}
    >
      {children}
    </button>
  );
}
