import ImageOne from "../images/gallery/one.jpg";
import ImageTwo from "../images/gallery/two.jpg";
import ImageThree from "../images/gallery/three.jpg";
import ImageFour from "../images/gallery/four.jpg";
import ImageFive from "../images/gallery/five.jpg";
import ImageSix from "../images/gallery/six.jpg";
import ImageSeven from "../images/gallery/seven.jpg";
import ImageEight from "../images/gallery/eight.jpg";
import ImageNine from "../images/gallery/nine.jpg";
import ImageEleven from "../images/gallery/eleven.jpg";
import ImageTwelve from "../images/gallery/twelve.jpg";

import OneTon from "../images/one_ton.svg";
import OneFiveTon from "../images/one_five_ton.svg";
import threeTon from "../images/three_ton.svg";
import eightTon from "../images/eight_ton.svg";
import moment from "moment";

export const DEBUG = false;
export const DATETIME_ERROR_MESSAGE =
  "Booking date must be atleast 1:30 a head";

export const FLOORS_ARRAY = Array(11).fill(0);
export const HELPERS_ARRAY = Array(3).fill(0);
export const BUTTON_VARIANT = {
  hide: {
    backgroundColor: "rgb(115, 194, 251, 0.0)",
  },
  show: {
    backgroundColor: "rgb(115, 194, 251, 0.75)",
  },
  exit: {
    backgroundColor: "rgb(115, 194, 251, 0.0)",
  },
};

const date = new Date();
export const DEFAULT_ADDITIONAL_INFORMATION = {
  booking_date: moment(date).format("yyyy-MM-DD"),
  booking_time: `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`,
};

export const SECTION_SUBHEADER_FONTSIZE = 15;
export const SECTION_ANIMATION_DURATION = 0.5; //seconds
export const SECTION_ANIMATION_DELAY = 0.1; // seconds

export const OUR_MISSION =
  "We are passionate about the mobility industry. Matols Logistics Services offers removal services for both industrial and corporates in Gauteng and all other provinces countrywide. Consumer oriented, we focus on our clients satisfaction and strive to provide our clients with the best services at the best prices.";

export const ABOUT_US =
  "Our Local Removal teams have size relavant trucks ranging from 1 ton to 8 tons. Discover Matols Logistics Services, your trusted partner for seamless moving experiences in Gauteng and South Africa. With our expert team and tailored solutions, we ensure a stress-free removal process, whether you're moving in Gauteng or across South Africa. Explore our comprehensive services and experience the highest standards of professionalism, effiency and customer satisfaction";

export const BASE_AMOUNT =
  "Base amount includes additional helper fee, floors to carry up or down fee etc ...";
export const MID_MONTH_DISCOUNT =
  "Discount given to our customers in the middle of the month";
export const PRICE_ADJUSTMENT =
  "Price adjustment fee as agreed with between admin and customer";
export const LOYAL_CUSTOMER_DISCOUNT =
  "Discount given to loyal customers or return customers";

export const AMOUNT_DUE = "Amount to be settled by the customer on delivery";

export const WHY_CHOOSE_US = [
  {
    header: "Secure card vault",
    description:
      "PLease tell me that you love me because thats all I wanted to hear from here from the moment i entered",
  },
  {
    header: "Secure card vault",
    description:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
  {
    header: "Streamlined checkout",
    description:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
  {
    header: "Secure card vault",
    description:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
  {
    header: "Secure card vault",
    description:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
];

export const OUR_SERVICES = [
  {
    header: "Office and Residential Removals",
    description:
      "Whether you are moving for the first time, relocating across town or across the country, we give you the peace of mind that your possessions will be cared for and protected during your move.",
  },
  {
    header: "Insurance",
    description:
      "All-risk in transit insurance can be arranged through our brokers which is then normally included in the quotation. It is recommended that you insure for current replacement value.",
  },
  {
    header: "Other Services",
    description:
      "Couriers and Garbage services. make a booking online and specify under the notes what will we be assisting you with Courieng and you all set, Matols Logistics Services will take care of the rest.",
  },
];

export const TESTIMONIALS = [
  {
    author: "Point to Point transportation",
    message:
      "1, Please tell yourself everyday that you love me and that you want to be with me",
  },
  {
    author: "Point to Point transportation",
    message:
      "Please tell yourself everyday that you love me and that you want to be with me",
  },
  {
    author: "Point to Point transportation",
    message:
      "Please tell yourself everyday that you love me and that you want to be with me",
  },
  {
    author: "Secure Card Vault",
    message:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
  {
    author: "Point to Point transportation",
    message:
      "Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item Please make sure that all we need is this item grid grid-cols-3 grid grid-cols-3grid grid-cols-3grid grid-cols-3",
  },
];

export const VEHICLE_TYPES = [
  {
    vehicle_type_svg: OneTon,
    vehicle_type: 1.0,
    vehicle_load_deck: "2.20 x 1.45 (m)",
    vehicle_max_weight: "1000 (kg)",
    vehicle_description:
      "is good when loading a double bed, single door fridge, a washing machine, dishwasher or a tumble dryer and a few other stuff e.g boxes and plastics.",
  },
  {
    vehicle_type_svg: OneFiveTon,
    vehicle_type: 1.5,
    vehicle_max_weight: "1300 (kg)",
    vehicle_load_deck: "2.78 x 1.63 (m)",
    vehicle_description:
      "is good when loading a double bed, 2 or 3 seater couch, double door fridge, a washing machine and a few other items e.g boxes and plastics.",
  },
  {
    vehicle_type_svg: threeTon,
    vehicle_type: 2.0,
    vehicle_max_weight: "1800 (kg)",
    vehicle_load_deck: "2.20 x 1.45 x 2 (m)",
    vehicle_description:
      "is suitable for small 2 bedroom apartment, 2x queen size beds, 3 or 2 sitter couch, standard size fridge, washing machine, TV stand and a few boxes.",
  },
  {
    vehicle_type_svg: threeTon,
    vehicle_type: 3.0,
    vehicle_load_deck: "4.30 x 1.80 (m)",
    vehicle_max_weight: "3000 (kg)",
    vehicle_description:
      "is suitable for 2 bedrooms, kitchen, sitting and dinning room and a few boxes/small items.",
  },
  {
    vehicle_type_svg: eightTon,
    vehicle_type: 8.0,
    vehicle_load_deck: "7.20 x 2.40 (m)",
    vehicle_max_weight: "6800 (kg)",
    vehicle_description:
      "is suitable when moving more big items especially when moving a 2 or 3 bedroom house with kitchen, sitting and dining rooms.",
  },
];

export const GALLERY = [
  {
    img: ImageFive,
  },
  {
    img: ImageSix,
  },
  {
    img: ImageSeven,
  },
  {
    img: ImageEight,
  },
  {
    img: ImageNine,
  },
  {
    img: ImageEleven,
  },
  {
    img: ImageTwelve,
  },
  {
    img: ImageOne,
  },
  {
    img: ImageTwo,
  },
  {
    img: ImageThree,
  },
  {
    img: ImageFour,
  },
];
