import React from "react";
import ReactDOM from "react-dom/client";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

import reportWebVitals from "./reportWebVitals";
import { QueryClientProvider, QueryClient } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { Navbar } from "./components/reusable_components/nav_bar";
import { Footer } from "./components/reusable_components/footer";
import { RegisteredRoutes } from "./utils/routes";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Matols /> */}
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Navbar />
        <RegisteredRoutes />
        <Footer />
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
