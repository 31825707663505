import { useEffect, useRef, useState } from "react";
import { ROUTE_LINKS } from "../../utils/route_links";
import { Squasher } from "../hamburger/squasher";
import { Menu } from "./navbar-content/Menu";
import { Logo } from "./navbar-content/logo";
import { useResponsiveView } from "../../utils/hooks/use_responsive_view";
import { useIsSignedIn } from "../../utils/hooks/use_is_signed_in";
import {
  SignedInMobileMenu,
  SignedOutMobileMenu,
} from "./navbar-content/toogle_menu";
import { Link } from "react-router-dom";

//website navbar
export function Navbar() {
  // refe
  const mobileRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { screenWidth } = useResponsiveView();
  const { isSignedIn, userInitials } = useIsSignedIn();

  const toogleNavMenu = (toogle) => {
    setIsOpen(toogle);
    mobileRef.current.classList.toggle("hidden");
  };

  useEffect(() => {
    if (isOpen) {
      toogleNavMenu(!isOpen);
    }
  }, [screenWidth]);

  return (
    <header className="bg-slate-800 text-yellow-500 sticky top-0 z-20">
      <section className="max-w-4xl mx-auto pl-6 pr-4 py-6 flex justify-between items-center">
        <Logo />
        <div className="flex flex-row justify-center items-center gap-1">
          <Link
            reloadDocument
            to={ROUTE_LINKS.profile}
            className="cursor-pointer text-lg hover:opacity-75 visible sm:hidden"
          >
            {console.log(userInitials)}
            {userInitials}
          </Link>
          <Squasher
            id="HAMBURGER_ICON"
            size={25}
            rounded={true}
            toggled={isOpen}
            duration={0.3}
            onToggle={toogleNavMenu}
          />
        </div>
        <Menu />
      </section>

      <section
        ref={mobileRef}
        id="mobile-menu"
        className="absolute top-[104px] bg-slate-700  w-full text-5xl flex-col justify-center
        origin-top animate-open-menu hidden z-20"
      >
        {isSignedIn ? <SignedInMobileMenu /> : <SignedOutMobileMenu />}
      </section>
    </header>
  );
}
