import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";

export function useDecodeJwt() {
  const [jwtToken, setJwtToken] = useState("");
  const [decodedJwt, setDecodedJwt] = useState("");

  useEffect(() => {
    if (jwtToken) {
      try {
        setDecodedJwt((r) => jwtDecode(jwtToken));
      } catch (error) {
        // console.log("");
      }
    }
  }, [jwtToken]);

  return { decodedJwt, setJwtToken };
}
