import axios from "axios";
import { axiosHeaders } from "../utils/hooks/use_axios_headers";
import { DEBUG } from "../utils/information";
// export
const base_url = DEBUG
  ? "http://localhost:8000/"
  : "https://matols-logistics-services-6e660bee869e.herokuapp.com/";
const axiosHeader = axiosHeaders();

export const API = axios.create({
  baseURL: base_url,
  headers: axiosHeader,
});

// export sign in
export const signInFunction = async (data) => {
  const response = await API.post("users/api/user/signin/", data);
  return response.data;
};

export const registerFunction = async (data) => {
  const response = await API.post("users/api/user/create/", data);
  return response.data;
};

export const signoutFunction = async () => {
  const response = await API.post("users/api/user/signout/");
  return response.data;
};

export const fetchUserProfile = async () => {
  const response = await API.get("users/api/user/retrieve/");
  return response.data;
};

export const updateUserProfile = async (data) => {
  const response = await API.put("users/api/user/update/", data);
  return response.data;
};

export const fetchUserBookings = async (currentPage) => {
  const response = await API.get(
    `bookings/api/retrieve/user/?p=${currentPage}`
  );
  return response.data;
};

export const createBookingFunction = async (data) => {
  const response = await API.post("bookings/api/booking/create/", data);
  return response.data;
};

export const computeDistanceFunction = async (data) => {
  const response = await API.post(
    "bookings/api/booking/compute/distance/",
    data
  );
  return response.data;
};

export const fetchBookingFunction = async (bookingId) => {
  const response = await API.get(`bookings/api/booking/${bookingId}/retrieve/`);
  return response.data;
};

export const updateBookingFunction = async (data) => {
  const response = await API.put(
    `bookings/api/booking/${data.id}/update/`,
    data
  );
  return response.data;
};

export const computeQuoteFunction = async (data) => {
  const response = await API.post("bookings/api/booking/compute/quote/", data);
  return response.data;
};

export const fetchFeedBackFunction = async () => {
  const response = await API.get("feed/back/api/fetch/");
  return response.data;
};

export const submitContactUsFunction = async (data) => {
  const response = await API.post("contact/us/api/create/", data);
  setTimeout(() => {}, 5000);
  return response.data;
};

export const fetchFaqsFunction = async () => {
  const response = await API.get(
    "frequently/asked/questions/api/retrieve/all/"
  );
  return response.data;
};

export const requestPasswordResetFunc = async (data) => {
  const response = await API.post("users/api/user/password/reset/", data);
  return response.data;
};

export const resetPasswordFunc = async (data) => {
  const response = await API.post(
    "users/api/user/password/reset/confirm/",
    data
  );
  return response.data;
};
