export const ROUTE_LINKS = {
  home: "/",
  bookings: "/bookings",
  sigin: "/user/signin",
  register: "/user/register",
  profile: "/profile",
  faqs: "/frequently/asked/questions",
  feedback: "/feed/back",
  viewbooking: "/bookings/booking/:id/view/",
  updatevehicletype: "/bookings/booking/:id/update/vehicle/type",
  updateadditionalinformation:
    "/bookings/booking/:id/update/additional/information",
  updateroutes: "/bookings/booking/:id/update/routes",
  makebooking: "/create/booking/book",
  createbooking: "/create/booking/vehicle/type",
  chooseroutes: "/create/booking/routes",
  chooseadditionalinformation: "/create/booking/additional/information",
  generatequote: "/create/booking/generate/quote",
  updatebooking: "/update/booking/:id/",
  resetpassword: "/password/reset/:token",
  requestpasswordreset: "/request/password/reset",
  createcompletebooking: "/bookings/booking/:pk/complete",
};
