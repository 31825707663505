import { useEffect, useState } from "react";

export function useResponsiveView() {
  const [screenType, setScreenType] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  //set screen type\
  const screenTypeFunc = (dynamicWidth) => {
    // if
    if (dynamicWidth <= 640) setScreenType((r) => "sm");
    else if (dynamicWidth > 640 && dynamicWidth <= 768)
      setScreenType((r) => "md");
    else if (dynamicWidth > 768 && dynamicWidth <= 1024)
      setScreenType((r) => "lg");
    else if (dynamicWidth > 1024 && dynamicWidth <= 1280)
      setScreenType((r) => "xl");
    else setScreenType((r) => "2xl");
  };

  useEffect(() => {
    screenTypeFunc(screenWidth);
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenWidth]);

  // return
  return { screenWidth, screenType };
}
