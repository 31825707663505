import logo from "../../../images/logo.svg";
import { motion, useAnimation } from "framer-motion";
import { BUTTON_VARIANT } from "../../../utils/information";
import { useTimeout } from "../../../utils/hooks/use_time_out";
import { ROUTE_LINKS } from "../../../utils/route_links";

export function Logo({ ...props }) {
  const animation = useAnimation();
  const { set } = useTimeout(() => animation.start("hide"), 100);
  const { set: navigate_home } = useTimeout(
    () => (window.location.href = ROUTE_LINKS.home),
    500
  );
  return (
    <motion.div
      {...props}
      variants={BUTTON_VARIANT}
      animate={animation}
      exit="exit"
      transition={{ duration: 0.5 }}
    >
      <img
        onClick={() => {
          animation.start("show");
          set();
          navigate_home();
        }}
        className="h-20 sm:h-24"
        src={logo}
        alt="..."
      ></img>
    </motion.div>
  );
}

{
  /* <a href={ROUTE_LINKS.home}>
         
</a> */
}
