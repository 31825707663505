import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import moment from "moment";

export function cn(...input) {
  return twMerge(clsx(input));
}

export function dateTimeDifference(datetime) {
  return moment(datetime).diff(moment(), "second");
}
