import { LOCAL_STORAGE_KEYS } from "../local_storage_keys";

export function axiosHeaders() {
  let tokens;
  try {
    const item = window.localStorage.getItem(LOCAL_STORAGE_KEYS.tokens);
    tokens = item ? JSON.parse(item) : undefined;
  } catch (error) {
    tokens = undefined;
  }
  const axiosHeader = tokens
    ? {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokens.access}`,
      }
    : {
        "Content-Type": "application/json",
      };

  return axiosHeader;
}
